<template>
  <div>
    <div class="box p-5 zoom-in scroll-ajuste">
      <p class="font-medium mb-2" style="font-size: 16px">Remisiones vs Facturación</p>
      <div class="grid grid-cols-2 gap-4" >
        <div class=" gap-4">
          <div class="flex flex-col">
                    <span class="font-medium">
                        Valor Total Remisiones
                    </span>
            <span class="text-gray-600 mt-2">
                        {{$h.formatNumber(totalRemisiones)}}
                    </span>
          </div>
          <div class="flex flex-col">
                    <span class="font-medium">
                        Valor Total Facturado
                    </span>
            <span class="text-gray-600 mt-2">
                        {{$h.formatNumber(totalFacturas)}}
                    </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { storeCardRemisionVsFacturas } from './store/cardRemisionVsFacturas.store'
import { computed } from 'vue'
export default {
  name: 'cardRemisionVsFacturas',
  setup () {
    const useCardRemisionVsFacturas = storeCardRemisionVsFacturas()
    const totalRemisiones = computed(() => {
      return useCardRemisionVsFacturas.totalRemisiones
    })
    const totalFacturas = computed(() => {
      return useCardRemisionVsFacturas.totalFacturas
    })
    return {
      totalRemisiones,
      totalFacturas
    }
  }
}
</script>
<style scoped>

</style>
